






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    InsCurrentTab1: () => import('@/components/business/cms/InsCurrentTab1.vue')
  }
})
export default class InsCmsCat10 extends Vue {
  @Prop() private cmsCategoryChilds!: object;
  memberFees: object = {};
  memberApply: object = {};
  memberPayMethods: object = {};
  contactMethods: object = {};
  fee1: object[] = [];
  fee2: object[] = [];
  content: any = '';
  Signer: any = null;

  handleCmsData () {
    // 入會費用
    this.memberFees = this.cmsCategoryChilds[0];
    // 申請方法
    this.memberApply = this.cmsCategoryChilds[1];
    // 付款方式
    this.memberPayMethods = this.cmsCategoryChilds[2];
    // 联系方式数据
    this.getContactByDevice();
  }
  getContactByDevice () {
    this.$Api.cms
      .getCategoryByDevice({ CatId: 40112, IsMobile: this.isMobile })
      .then(result => {
        console.log(result, '联系方式数据');
        if (result.Children && result.Children.length > 4) {
          this.contactMethods = { 'Children': [result.Children[0], result.Children[1], result.Children[3], result.Children[2]] };
        }
      });
  }
  getForm () {
    this.$Api.regAndPay.getHtml('Inquiry', this.currentlang, this.isMobile).then(result => {
      this.content = result.HtmlString;

      this.$nextTick(() => {
        if (document.querySelectorAll('#Sign').length > 0) {
          this.Signer = new intimex.CanvasSigner('#NewSignCanvas', '#Signature', {
            color: '#58B63A',
            width: 5
          });
          this.Signer.initCanvas();

          window['Signer'] = this.Signer;
        }

        let _this = this;
        document.dispatchEvent(new Event('rnpFinshed'));
        document.querySelectorAll('input[type="text"],input[type="email"]').forEach(function (inputEl) {
          inputEl.addEventListener('focus', _this.onInputFocus);
          inputEl.addEventListener('blur', _this.onInputBlur);
        });
      });
    });
  }
  onInputFocus (e) {
    e.target.parentNode.parentNode.classList.add('focus');
  }

  onInputBlur (e) {
    e.target.parentNode.parentNode.classList.remove('focus');
  }
  get currentlang () {
    return this.$Storage.get('locale');
  }
  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  get queryLang () {
    return this.$route.query.Lang || '';
  }
  // 引入外部js
  LoadScript (src) {
    const oScript = document.createElement('script');
    oScript.type = 'text/javascript';
    oScript.src = src;
    document.body.appendChild(oScript);
  }
  created () {
    this.handleCmsData();
    window['jsData'] = {
      id: this.$route.params.id,
      returnUrl: window.location.host, // domain
      IsLogin: false, // 登录状态
      IsMobile: this.isMobile,
      HasPreview: true,
      PreviewTitleText: this.$t('RegNPay.PreviewTitleText'),
      UploadButtonText: this.$t('RegNPay.UploadButtonText'),
      UploadingText: this.$t('RegNPay.UploadingText'),
      UploadSuccessfulText: this.$t('RegNPay.UploadSuccessfulText'),
      UploadFailText: this.$t('RegNPay.UploadFailText'),
      NoFileText: this.$t('RegNPay.NoFileText'),
      UploadLengthText: this.$t('RegNPay.UploadLengthText'),
      UploadSizeText: this.$t('RegNPay.UploadSizeText'),
      BackText: this.$t('RegNPay.BackText'),
      ConfirmText: this.$t('RegNPay.ConfirmText'),
      PleaseSelect: this.$t('RegNPay.PleaseSelect'),
      Version: '2.0'
    };

    // 引入外部js
    // this.LoadScript('http://pm.dev.in-store.hk:84/scripts/store/regnpay-2.0.js'); // 本地测试
    this.LoadScript('/ClientResources/Script/regnpay-2.0.js'); // dev || uat
    this.LoadScript('/static/js/CanvasSigner.js');
    this.LoadScript('/static/js/ajaxFileUpload.js');

    // RNP Form后台预览跳转语言判断
    if (this.queryLang) {
      this.$Api.member.setUILanguage(this.queryLang).then((result) => {
        this.$i18n.locale = this.queryLang as string;
        localStorage.setItem('locale', this.queryLang as string);
        this.getForm();
      }).catch((error) => {
        console.log(error);
      });
    } else {
      this.getForm();
    }
  }
  mounted () {
    window['regAndPay'] = this.$Api.regAndPay;
    window['router'] = this.$router;
    window['Elalert'] = this.$alert;
  }
  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getForm();
  }
  @Watch('id', { deep: true })
  onKeyChange () {
    this.getForm();
  }
}
